import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppComfirmComponent} from '../services/app-confirm/AppConfirmComponent';
import {AppLoaderComponent} from '../services/app-loader/app-loader.component';
import {MaterialModule} from '../material.module';
import {AuthCallbackComponent} from './AuthCallback';

const components = [
  AppLoaderComponent,
  AppComfirmComponent,
  AuthCallbackComponent
]
@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: components,
  entryComponents: [AppComfirmComponent,
    AppLoaderComponent]
})
export class SharedComponentsModule { }
