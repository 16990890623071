import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';

import {MaterialModule} from './material.module';
import {SharedPipesModule} from './pipes/shared-pipes.module';
import {SharedDirectivesModule} from './directives/shared-directives.module';
import {SharedComponentsModule} from './components/shared-components.module';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {AppConfirmService} from './services/app-confirm/app-confirm.service';
import {AppLoaderService} from './services/app-loader/app-loader.service';

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedComponentsModule,
    TranslateModule
  ],
  declarations: [BreadcrumbComponent],
  exports: [
    SharedPipesModule,
    SharedDirectivesModule,
    BreadcrumbComponent,
    SharedComponentsModule
    ],
  providers: [
    AppConfirmService,
    AppLoaderService
  ]
})
export class SharedModule {
}
