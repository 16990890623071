<div class="container">
    <h2 mat-dialog-title>{{'button.new_report' | translate}} </h2>
    <mat-dialog-content>
        <form [formGroup]="reportForm">
            <mat-vertical-stepper  #stepper>
                <mat-step >
                    <ng-template matStepLabel>{{'coach_name' | translate}}</ng-template>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label formControlName="coach">{{'coach_select' | translate}}</mat-label>
                        <mat-select>
                            <mat-option *ngFor="let coach of coaches" [value]="coach">{{coach.firstName}} {{coach.lastName}}</mat-option>
                          </mat-select>
                    </mat-form-field>
                </mat-step>
                <mat-step>
                        <ng-template matStepLabel>{{'choose_date' | translate}}</ng-template>
                        <mat-form-field appearance="fill" class="my-3">
                            <mat-label formControlName="date">{{'choose_date' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>          
                </mat-step>
                <mat-step>
                        <ng-template matStepLabel>{{'report_type' | translate}}</ng-template>
                            <div class="row">
                                <mat-checkbox class="example-margin" formControlName="learnerReport">{{'learner_support_report' | translate}}</mat-checkbox>
                            </div>
                            <div class="row">
                                <mat-checkbox class="example-margin" formControlName="adminReport">{{'training_admin_report' | translate}}</mat-checkbox>
                            </div>
                        <div class="my-3">
                          <button mat-button matStepperPrevious>{{'button.previous' | translate}}</button>
                          <button mat-button  [mat-dialog-close]="true" cdkFocusInitial (click)="openReport()">{{'button.next' | translate}}</button>
                          <button mat-button (click)="confirm()">{{'button.cancel' | translate}}</button>
                        </div>
                </mat-step>     
            </mat-vertical-stepper>
        </form>
    </mat-dialog-content>     
</div>