import { Routes, Route } from '@angular/router';

import { ShellComponent } from './shell.component';
import {AuthenticationGuard} from '@cbdc/eswp-client-core';
import { RoleAuthenticationGuard } from '@cbdc/eswp-client-core';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard, RoleAuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true, roles:['Admin','Manager'] }
    };
  }
}
