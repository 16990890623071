import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { HelpComponent } from './help/help.component';
import {TranslateModule} from '@ngx-translate/core';
import {FlexModule} from '@angular/flex-layout';

@NgModule({
  declarations: [ProfileComponent, HelpComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    TranslateModule,
    FlexModule
  ]
})
export class AccountModule { }
