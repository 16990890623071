import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CoachEndpointsService, CoachDto} from '@cbdc/eswp-api-training';

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.css']
})
export class AddReportComponent implements OnInit {

  reportForm: FormGroup;
  confirmationMessage: string;
  coaches: CoachDto[];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              public dialog: MatDialog,
              private coachService: CoachEndpointsService,
              private translate: TranslateService) {
    this.translate.get('confirmationMessage').subscribe(res => {
      this.confirmationMessage = res;
    });
  }

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({
      coach: ['', Validators.required],
      date: ['', Validators.required],
      learnerReport: false,
      adminReport: false
    });
    this.getCoach();

  }

  openReport() {
    if (this.reportForm.get('learnerReport').value) {
      this.router.navigate(['/add_learner_report'])
    }
    if (this.reportForm.get('adminReport').value) {
      this.router.navigate(['/add_admin_report'])
    }
  }

  confirm() {
    if (confirm(this.confirmationMessage)) {
      this.dialog.closeAll();
    }
  }

  getCoach() {
    this.coachService.coachList().subscribe(res => {
      this.coaches = res.items;
    });
  }

  saveReport() {

  }

}
