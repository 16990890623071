import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Configuration} from '../types';
import {Logger} from '../logger.service';
import Swal from 'sweetalert2';

const log = new Logger('ErrorHandlerInterceptor');
import {SecurityService} from '../services/security.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private securityService: SecurityService;

  constructor(private configuration: Configuration,
              private injector: Injector) {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.securityService = injector.get(SecurityService);
    }, 500);

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!this.configuration.production) {
      // Do something with the error

      log.error('Request error', response);
    }

    const httpErrorCode = response['status'];
    switch (httpErrorCode) {
      case 401:
        console.log('not authorized');
        this.securityService.Authorize();
        break;
      case 403:
        //window.location.href = '/account/403';
        break;
      case 422:
        Swal.fire('Oops ...', response['message'], 'error');
        break;
      case 404:
        break;
      case 400:
        Swal.fire('Oops ...', this.formatErrors(response['error']), 'error');
        break;
      default:
        Swal.fire('Oops ...', response['message'], 'error');
    }
    throw response;
  }

  private formatErrors(error: any): string {
    let html = `<h4>${error.title}</h4>`;
    Object.keys(error.errors).forEach(key => {
        const messages = error.errors[key];
        html += '<ul>';
        messages.forEach(p => html += `<li>${p}</li>`);
        html += '</ul>';
      }
    );
    return html;
  }
}
