import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// import { ApiModule, Configuration, ConfigurationParameters } from '@cbdc/eswp-core-api';
import {ApiModule, Configuration, ConfigurationParameters} from '@cbdc/eswp-api-core';
import {
  ApiModule as HrApiModule,
  Configuration as HrApiConfiguration,
  ConfigurationParameters as HrApiConfigurationParameters
} from '@cbdc/eswp-api-hr';
import {
  ApiModule as CoreApiModule,
  Configuration as CoreApiConfiguration,
  ConfigurationParameters as CoreApiConfigurationParameters
} from '@cbdc/eswp-api-core';
import {
  ApiModule as OrganisationApiModule,
  Configuration as OrganisationApiConfiguration,
  ConfigurationParameters as OrganisationApiConfigurationParameters
} from '@cbdc/eswp-api-organisation';
import {
  ApiModule as LmsApiModule,
  Configuration as LmsApiConfiguration,
  ConfigurationParameters as LmsApiConfigurationParameters
} from '@cbdc/eswp-api-lms';
import {
  ApiModule as IdentityApiModule,
  Configuration as IdentityApiConfiguration,
  ConfigurationParameters as IdentityApiConfigurationParameters
} from '@cbdc/eswp-api-identity';
import {
  CoreModule,
  Configuration as CoreConfiguration,
  ConfigurationParameters as CoreConfigurationParameters
} from '@cbdc/eswp-client-core';
import {
  ApiModule as TrainingApiModule,
  Configuration as TrainingApiConfiguration,
  ConfigurationParameters as TrainingApiConfigurationParameters
} from '@cbdc/eswp-api-training';
import {environment} from '../environments/environment';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import {MaterialModule, SharedModule, ShellModule} from '@cbdc/eswp-client-ui';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import {AddReportComponent} from './modules/logbook/add-report/add-report.component';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ComponentsModule} from './Components/components.module';
import {DatePipe} from '@angular/common';


export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.API_BASE_PATH
  };
  return new Configuration(params);
}

export function trainingApiConfigFactory(): TrainingApiConfiguration {
  const params: TrainingApiConfigurationParameters = {
    basePath: environment.API_GATEWAY + 'training'
  };
  return new TrainingApiConfiguration(params);
}

export function hrApiConfigFactory(): HrApiConfiguration {
  const params: HrApiConfigurationParameters = {
    basePath: environment.API_GATEWAY + 'hr'
  };
  return new HrApiConfiguration(params);
}

export function identityApiConfigFactory(): IdentityApiConfiguration {
  const params: IdentityApiConfigurationParameters = {
    basePath: environment.API_GATEWAY + 'identity'
  };
  return new IdentityApiConfiguration(params);
}

export function organisationApiConfigFactory(): OrganisationApiConfiguration {
  const params: OrganisationApiConfigurationParameters = {
    basePath: environment.API_GATEWAY + 'organisation'
  };
  return new OrganisationApiConfiguration(params);
}

export function lmsApiConfigFactory(): LmsApiConfiguration {
  const params: LmsApiConfigurationParameters = {
    basePath: environment.API_GATEWAY + 'lms'
  };
  return new LmsApiConfiguration(params);
}

export function coreApiConfigFactory(): CoreApiConfiguration {
  const params: CoreApiConfigurationParameters = {
    basePath: environment.API_GATEWAY + 'core'
  };
  return new CoreApiConfiguration(params);
}

export function coreConfigFactory(): CoreConfiguration {
  const params: CoreConfigurationParameters = {
    serverUrl: environment.API_GATEWAY,
    production: environment.production,
    loginUrl: '/account/login',
    identityUrl: environment.identityUrl
  };
  return new CoreConfiguration(params);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  entryComponents: [AddReportComponent],
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule.forRoot(coreConfigFactory),
    SharedModule,
    ShellModule,
    PerfectScrollbarModule,
    ApiModule.forRoot(apiConfigFactory),
    HrApiModule.forRoot(hrApiConfigFactory),
    TrainingApiModule.forRoot(trainingApiConfigFactory),
    OrganisationApiModule.forRoot(organisationApiConfigFactory),
    LmsApiModule.forRoot(lmsApiConfigFactory),
    CoreApiModule.forRoot(coreApiConfigFactory),
    IdentityApiModule.forRoot(identityApiConfigFactory),
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatStepperModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    NgxMaterialTimepickerModule,
    ComponentsModule,
    AppRoutingModule  // must be imported as the last module as it contains the fallback route

  ],
  providers: [
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    DatePipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
