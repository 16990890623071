import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NumberDirective} from './numbers-only.directive';
import {DropdownAnchorDirective} from './dropdown-anchor.directive';
import {DropdownLinkDirective} from './dropdown-link.directive';
import {DropdownDirective} from './dropdown.directive';


const directives = [
  NumberDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  DropdownDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule {
}
