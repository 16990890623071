<div fxFlex class="app-admin-wrap">
  <mat-sidenav-container fxFill >
    <mat-sidenav
      #sidenav
      [attr.role]="isMobile ? 'dialog' : 'navigation'"
      [mode]="isMobile ? 'over' : 'side'"
      [opened]="!isMobile"
      [disableClose]="!isMobile"
      ngClass.gt-sm="has-border"
      class="sidebar-full"
    >
      <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="app-logo" svgIcon="{{'TOOL_ICON' |translate}}"></mat-icon>
        <div fxLayout="column" fxFlex fxLayoutAlign="start center">
          <span translate>APP_NAME</span>
        </div>
      </mat-toolbar>
      <lib-sidenav [items]="menuItems"></lib-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary" class="mat-elevation-z6" fxLayoutAlign="center center">
        <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
<!--        <span fxFlex="40">{{ title }}</span>-->
        <div fxFlex>
          <h1 translate>TOOL_NAME &nbsp;</h1>
        </div>
        <div>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>apps</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="sia-mat-menu-panel">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <a *ngFor="let app of primaryApps" fxFlex="50" [href]="app.url">
                <button mat-menu-item>
                  <mat-icon style="font-size: 50px;" class="app-icon hr-tool" color="primary"
                            [svgIcon]="app.icon"></mat-icon>
                  {{app.name}}
                </button>
              </a>
            </div>
            <mat-divider class="mt-8"></mat-divider>
            <div fxLayout="row wrap" clas="mt-8" fxLayoutAlign="center">
              <a *ngFor="let app of secondaryApps" fxFlex="50" [href]="app.url">
                <button mat-menu-item>
                  <mat-icon class="app-icon hr-tool" color="primary" [svgIcon]="app.icon"></mat-icon>
                  {{app.name}}
                </button>
              </a>
            </div>
          </mat-menu>
        </div>
        <div>
          <button mat-icon-button [matMenuTriggerFor]="userMenu" translate>
            <mat-icon>person</mat-icon>
          </button>
          <mat-menu #userMenu="matMenu" class="sia-mat-menu-panel">
            <div fxLayout="column" class="ml-16" fxLayoutAlign="space-around center">
              <!-- <span translate>{{'Logged in as' | translate}}</span> -->
              <span translate>Logged in as</span>
              <span ><b>{{ userData?.preferred_username }}</b></span>
              <button mat-menu-item (click)="navigateToProfile()" translate>button.account</button>
              <button mat-menu-item (click)="logout()" translate>Logout</button>
            </div>
          </mat-menu>
        </div>
        <!--        <div>-->
        <!--          <button mat-icon-button [matMenuTriggerFor]="languageMenu">-->
        <!--            {{CurrentLanguage}}-->
        <!--          </button>-->
        <!--          <mat-menu #languageMenu="matMenu">-->
        <!--            <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">-->
        <!--              {{ language }}-->
        <!--            </button>-->
        <!--          </mat-menu>-->
        <!--        </div>-->
        <button mat-icon-button class="rig" [matMenuTriggerFor]="moreMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu">
<!--          <button mat-menu-item [routerLink]="['/settings']">-->
<!--            <mat-icon>settings</mat-icon>-->
<!--            {{'label.settings' | translate}}-->
<!--          </button>-->
          <button mat-menu-item [routerLink]="['/settings/help']">
            <mat-icon>help</mat-icon>
            {{'label.help' | translate}}
          </button>
 
        </mat-menu>
      </mat-toolbar>
      <mat-toolbar *ngIf="!hideTaskBar">
        <button mat-icon-button (click)="back()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <lib-breadcrumb></lib-breadcrumb>
        <div fxLayout="row" fxFlex="1 1 0%" fxLayoutAlign="end center">
          <button *ngFor="let item of taskbarMenuItems"
                  mat-raised-button
                  color="primary"
                  class="taskbar-button"
                  [routerLink]="item.state"
                  routerLinkActive="router-link-active"
          >
            {{item.name | translate}}
          </button>
        </div>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
