import { Injectable, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface ITheme {
  name: string;
  baseColor?: string;
  isActive?: boolean;
}

@Injectable()
export class ThemeService {
  public egretThemes: ITheme[] = [
    {
      name: 'eswp-persian-green-theme',
      baseColor: '#00a8a2',
      isActive: false
    },
    {
      name: 'eswp-grey-theme',
      baseColor: '#999999',
      isActive: false
    },
    {
      name: 'eswp-blue-theme',
      baseColor: '#144d98',
      isActive: false
    },
    {
      name: 'eswp-green-theme',
      baseColor: '#009345',
      isActive: false
    },
    {
      name: 'eswp-green-apple-theme',
      baseColor: '#6cbe45',
      isActive: false
    },
    {
      name: 'eswp-cardinal-pink-theme',
      baseColor: '#8a0d72',
      isActive: false
    },
    {
      name: 'eswp-tango-theme',
      baseColor: '#f37121',
      isActive: false
    },
  ];
  public activatedTheme: ITheme;
  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: any) {}

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme(r: Renderer2, themeName: string) {
    this.renderer = r;

    this.activatedTheme = this.egretThemes[4];

    // this.changeTheme(themeName);
    this.renderer.addClass(this.document.body, themeName);
  }

  changeTheme(prevTheme: string, themeName: string) {
    this.renderer.removeClass(this.document.body, prevTheme);
    this.renderer.addClass(this.document.body, themeName);
    this.flipActiveFlag(themeName);
  }

  flipActiveFlag(themeName: string) {
    this.egretThemes.forEach(t => {
      t.isActive = false;
      if (t.name === themeName) {
        t.isActive = true;
        this.activatedTheme = t;
      }
    });
  }
}
