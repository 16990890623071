import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';

import {MaterialModule} from '../material.module';
import {ShellComponent} from './shell.component';
import {SidenavComponent} from './sidenav/sidenav.component';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from '../shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, FlexLayoutModule, MaterialModule, RouterModule, HttpClientModule, SharedModule],
  declarations: [ShellComponent, SidenavComponent],
  exports: [ShellComponent, SidenavComponent]
})
export class ShellModule {
}
