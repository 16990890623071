import {Injectable} from '@angular/core';

import {
  OccupationalGroupEndpointsService, OccupationalGroupDto,
  EducationLevelEndpointsService, EducationLevelDto,
  AgeClassEndpointsService, AgeClassDto,
  EmploymentStatusEndpointsService, EmploymentStatusDto,
  GenderEndpointsService, GenderDto,
  EssentialSkillService, EssentialSkill,
  EssentialSkillLevelEducationService, EssentialSkillLevelEducation
} from '@cbdc/eswp-api-hr';
import {
  LanguageEndpointsService, LanguageDto,
  RegionEndpointsService, RegionDto,
  AppEndpointsService, AppDto
} from '@cbdc/eswp-api-core';
import {
  BusinessMarketsService, BusinessMarket,
  BusinessStructuresService, BusinessStructure,
  ManufacturingSubSectorsService, ManufacturingSubSector
} from '@cbdc/eswp-api-organisation';
import {Observable} from 'rxjs';
import {CacheService} from './cache.service';
import {map} from 'rxjs/operators';


@Injectable()
export class ValueObjectService {

  constructor(private cacheService: CacheService,
              private regionService: RegionEndpointsService,
              private occupationalGroupService: OccupationalGroupEndpointsService,
              private ageService: AgeClassEndpointsService,
              private educationLevelService: EducationLevelEndpointsService,
              private employmentStatusService: EmploymentStatusEndpointsService,
              private genderService: GenderEndpointsService,
              private languageService: LanguageEndpointsService,
              private manufacturingSubSectorService: ManufacturingSubSectorsService,
              private businessMarketService: BusinessMarketsService,
              private businessStructureService: BusinessStructuresService,
              private essentialSkillService: EssentialSkillService,
              private appService: AppEndpointsService,
              private essentialSkillLevelEducationService: EssentialSkillLevelEducationService) {
  }

  getRegions(): Observable<RegionDto[]> {
    return this.cacheService.get('regions', this.regionService.regionList());
  }

  getProvinces(): Observable<RegionDto[]> {
    return this.cacheService.get('provinces', this.regionService.regionList().pipe(map((regions) => {
        return regions.filter(region => region.id >= 10);
      }),
    ));
  }

  getOccupationalGroups(): Observable<OccupationalGroupDto[]> {
    return this.cacheService.get('occupationalGroups', this.occupationalGroupService.occupationalgroupList());
  }

  getAges(): Observable<AgeClassDto[]> {
    return this.cacheService.get('ages', this.ageService.ageclassList());
  }

  getEducationLevels(): Observable<EducationLevelDto[]> {
    return this.cacheService.get('education_levels', this.educationLevelService.educationlevelList());
  }

  getEmployementStatus(): Observable<EmploymentStatusDto[]> {
    return this.cacheService.get('employment_statuses', this.employmentStatusService.employmentstatusList());
  }

  getGenders(): Observable<GenderDto[]> {
    return this.cacheService.get('genders', this.genderService.genderList());
  }

  getLanguages(): Observable<LanguageDto[]> {
    return this.cacheService.get('languages', this.languageService.languageList());
  }

  getManufacturingSubSectors(): Observable<ManufacturingSubSector[]> {
    return this.cacheService.get('manufacturing_sub_sectors', this.manufacturingSubSectorService.apiV1ManufacturingSubSectorsGet());
  }

  getBusinessMarkets(): Observable<BusinessMarket[]> {
    return this.cacheService.get('business_markets', this.businessMarketService.apiV1BusinessMarketsGet());
  }

  getBusinessStructures(): Observable<BusinessStructure[]> {
    return this.cacheService.get('business_structures', this.businessStructureService.apiV1BusinessStructuresGet());
  }

  getEssentialSkills(): Observable<EssentialSkill[]> {
    return this.cacheService.get('essential_skills', this.essentialSkillService.apiV1EssentialSkillGet());
  }

  getApps(): Observable<AppDto[]> {
    return this.cacheService.get('apps', this.appService.appList());
  }

  getEssentialSkillLevelEducation(): Observable<EssentialSkillLevelEducation[]> {
    return this.cacheService.get('', this.essentialSkillLevelEducationService.apiV1EssentialSkillLevelEducationGet());
  }
}
