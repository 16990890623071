import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';

import {Observable, Subject} from 'rxjs';

export interface IConfiguration {
  identityUrl: string;
  marketingUrl: string;
  purchaseUrl: string;
  signalrHubUrl: string;
  activateCampaignDetailFunction: boolean;
}

@Injectable()
export class ConfigurationService {
  serverSettings: IConfiguration;
  // observable that is fired when settings are loaded from server
  private settingsLoadedSource = new Subject();
  settingsLoaded$ = this.settingsLoadedSource.asObservable();
  isReady = false;

  constructor(private http: HttpClient, private storageService: StorageService) {
  }

  load() {
    const url = `/Home/Configuration`;
    this.http.get(url).subscribe((response) => {
      console.log('server settings loaded');
      this.serverSettings = response as IConfiguration;
      console.log(this.serverSettings);
      this.storageService.store('identityUrl', this.serverSettings.identityUrl);
      this.isReady = true;
      this.settingsLoadedSource.next();
    });
  }
}
