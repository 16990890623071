export interface ConfigurationParameters {
  production?: boolean;
  serverUrl?: string;
  loginUrl?: string;
  identityUrl?: string;
}


export class Configuration {
  production: boolean;
  serverUrl: string;
  loginUrl: string;
  identityUrl: string;
  constructor(configurationParameters: ConfigurationParameters = {}) {
    this.production = configurationParameters.production;
    this.serverUrl = configurationParameters.serverUrl;
    this.loginUrl = configurationParameters.loginUrl;
    this.identityUrl = configurationParameters.identityUrl;
  }
}
