import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BarChartsComponent} from './bar-charts/bar-charts.component';
import {ChartsModule} from 'ng2-charts';
import {DialogCancelFormComponent} from './dialog-cancel-form/dialog-cancel-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ChartsComponent} from './charts/charts.component';


@NgModule({
  declarations: [BarChartsComponent, ChartsComponent, DialogCancelFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    ChartsModule
  ],
  exports: [ChartsComponent, BarChartsComponent, DialogCancelFormComponent]
})
export class ComponentsModule {
}
