import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {RoutePartsService} from '@cbdc/eswp-client-core';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  routeParts: any[];
  routerEventSub: Subscription;

  constructor(
    private router: Router,
    private routePartsService: RoutePartsService,
    private activeRoute: ActivatedRoute) {
    this.routerEventSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        this.parseRoute();
      });
  }

  ngOnInit() {
    this.parseRoute();
  }


  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  parseRoute() {
    this.routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);

    this.routeParts.reverse().map((item, i) => {
      item.breadcrumb = this.parseText(item);
      item.urlSegments.forEach((urlSegment, j) => {
        if (j === 0) {
          return item.url = `${urlSegment.path}`;
        }
        item.url += `/${urlSegment.path}`;
      });
      if (i === 0) {
        return item;
      }
      // prepend previous part to current part
      item.url = `${this.routeParts[i - 1].url}/${item.url}`;
      return item;
    });
  }

  parseText(part) {
    if (!part.breadcrumb) {
      return '';
    }
    part.breadcrumb = part.breadcrumb.replace(/{{([^{}]*)}}/g, function(a, b) {
      const r = part.params[b];
      return typeof r === 'string' ? r : a;
    });
    return part.breadcrumb;
  }


}
