<div fxLayout="column" fxLayoutAlign="space-evenly center" class="mt-5 mx-40">
  <h1>{{'APP_NAME' | translate}}</h1>
  <div class="text-center my-40 mx-40">
    <h2 class="my-20">{{'label.free_support' | translate}}</h2>
    <p>{{'label.support_text' | translate }}</p>
    <h5><a href="tel:+18776208805">1-877-620-8805</a> {{'or' | translate}} <a href="mailto:info@skillsinaction.ca">info@skillsinaction.ca</a>.</h5>
  </div>
  <div class="my-40" fxLayout="column" fxLayoutAlign="space-around center">
    <span class="mx-40 text-center">{{'label.reportBug' | translate}}</span> 
    <a href="https://cbdcrestigouche.atlassian.net/rest/collectors/1.0/template/form/203404d4" target="_blank"
       class="mat-raised-button mat-button-base mat-primary mt-3">{{'button.reportBug' | translate}}</a>
  </div>
  <span class="mt-5">Version 1679</span>
</div>