import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Logger} from '../logger.service';
import {CredentialsService} from './credentials.service';
import {Configuration} from '../types';
import {SecurityService} from '../services/security.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router,
              private credentialsService: CredentialsService,
              private configuration: Configuration,
              private securityService: SecurityService,
              private authService: AuthenticationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.isDoneLoading$.pipe(
      filter(isDone => isDone),
      switchMap(_ => this.authService.isAuthenticated$),
      tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)),
    );
  }
}
