import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {I18nService} from './i18n.service';
import {Configuration} from './types';
import {CacheInterceptor} from './http/cache.interceptor';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HttpService} from './http/http.service';
import {RouteReuseStrategy} from '@angular/router';
import {RouteReusableStrategy} from './route-reusable-strategy';
import {ApiPrefixInterceptor} from './http/api-prefix.interceptor';
import {ErrorHandlerInterceptor} from './http/error-handler.interceptor';
import {HttpCacheService} from './http/http-cache.service';
import {CredentialsService} from './authentication/credentials.service';
import {AuthenticationService} from './authentication/authentication.service';
import {ThemeService} from './services/theme.service';
import {AuthenticationGuard} from './authentication/authentication.guard';
import { RoleAuthenticationGuard } from './authentication/authenticationRole.guard';
import {NavigationService} from './services/navigation.service';
import {ValueObjectService} from './services/value-object.service';
import {CacheService} from './services/cache.service';
import {RoutePartsService} from './services/route-parts.service';
import {SecurityService} from './services/security.service';
import {ConfigurationService} from './services/configuration.service';
import {StorageService} from './services/storage.service';
import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';

export class LocalNonceStorage implements OAuthStorage {
  private needsLocal(key: string) {
    return key === 'nonce' || key === 'PKCE_verifier';
  }

  getItem(key: string) {
    if (this.needsLocal(key)) {
      return localStorage.getItem(key);
    }
    return sessionStorage.getItem(key);
  }

  removeItem(key: string) {
    if (this.needsLocal(key)) {
      return localStorage.removeItem(key);
    }
    return sessionStorage.removeItem(key);
  }

  setItem(key: string, data: string) {
    if (this.needsLocal(key)) {
      return localStorage.setItem(key, data);
    }
    return sessionStorage.setItem(key, data);
  }
}

export function storageFactory(localNonceStorage: LocalNonceStorage): OAuthStorage {
  return localNonceStorage;
}

export function authConfigFactory(configuration: Configuration): AuthConfig {
  return {
    issuer: configuration.identityUrl,
    requireHttps: configuration.production ? true : false,
    skipIssuerCheck: configuration.production ? false : true,
    redirectUri: window.location.origin,
    clientId: 'webapp_manager',
    responseType: 'id_token token',
    scope: 'openid profile core organisation hr training performance lms identity',
    showDebugInformation: true,
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    requestAccessToken: true,
    disableAtHashCheck: true,
    oidc: true,
    disablePKCE: true,
    skipSubjectCheck: true,
  };
}


export function authModuleConfigFactory(configuration: Configuration): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [configuration.serverUrl],
      sendAccessToken: true,
    }
  };
}

@NgModule({
  providers: [
    ThemeService,
    SecurityService,
    AuthenticationService,
    CredentialsService,
    AuthenticationGuard,
    RoleAuthenticationGuard,
    I18nService,
    HttpCacheService,
    ApiPrefixInterceptor,
    // AuthInterceptor,
    ErrorHandlerInterceptor,
    NavigationService,
    CacheService,
    ValueObjectService,
    RoutePartsService,
    ConfigurationService,
    StorageService,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ],
  declarations: [],
  imports: [
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  exports: []
})
export class CoreModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: LocalNonceStorage, useClass: LocalNonceStorage},
        {provide: Configuration, useFactory: configurationFactory},
        {provide: OAuthModuleConfig, useFactory: authModuleConfigFactory, deps: [Configuration]},
        {provide: AuthConfig, useFactory: authConfigFactory, deps: [Configuration]},
        {provide: OAuthStorage, useFactory: storageFactory, deps: [LocalNonceStorage]}
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
