export class Menu {
  static basicMenu: any[] = [
    {
      name: 'menu.home',
      type: 'link',
      tooltip: 'Home',
      icon: 'home',
      state: 'home'
    },{
    name: 'menu.messages',
    text: 'Messages',
    type: 'link',
    tooltip: 'Messages',
    state: 'messages'
  }, {
    name: 'menu.log_book',
    type: 'link',
    tooltip: 'Logbook',
    state: 'logbook'
  }, {
    name: 'menu.six_steps',
    type: 'link',
    tooltip: 'Six steps',
    state: 'six_steps'
  },
  {
    name: 'menu.coach_guide',
    type: 'link',
    tooltip: 'Six steps',
    state: 'guide'
  },
  ];
}
