import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {
  AuthenticationService,
  I18nService,
  Logger,
  NavigationService,
  ThemeService,
  untilDestroyed
} from '@cbdc/eswp-client-core';
import {environment} from '../environments/environment';
import {merge} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

import en from '../translations/en.json';
import fr from '../translations/fr.json';

import {Menu} from './menu';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'coach';

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private translateService: TranslateService,
              private i18nService: I18nService,
              private themeService: ThemeService,
              private renderer: Renderer2,
              private navService: NavigationService,
              private authService: AuthenticationService) {
    this.authService.runInitialLoginSequence();
  }


  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    log.debug('Set up Translations');
    this.i18nService.setTranslation('en', en);
    this.i18nService.setTranslation('fr', fr);
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    this.navService.setMenu(Menu.basicMenu);
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer, 'eswp-turquoise-yellow-theme');
  }
}
