import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  permittedRoles?: string[];
}

interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}


@Injectable()
export class NavigationService {
  constructor(private authService: AuthenticationService) {
  }

  basicMenu: IMenuItem[] = [
    {
      name: 'HOME',
      type: 'icon',
      tooltip: 'Home',
      icon: 'home',
      state: 'home'
    }];

  emptyMenu: IMenuItem[] = [];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  menuItems = new BehaviorSubject<IMenuItem[]>(this.basicMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  taskBarItems = new BehaviorSubject<IMenuItem[]>(this.emptyMenu);
  taskBarItems$ = this.taskBarItems.asObservable();

  public setMenu(items: IMenuItem[]): void {
    this.authService.isAuthenticated$.subscribe(() => {
      const m = items.filter((menuItem) => {
        if (menuItem.permittedRoles) {
          return this.authService.isInRole(menuItem.permittedRoles);
        } else {
          return true;
        }
      });
      this.menuItems.next(m);
    });
  }

  public setTaskbarMenu(items: IMenuItem[]): void {
    this.taskBarItems.next(items);
  }
}
