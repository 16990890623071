import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {Shell} from '@cbdc/eswp-client-ui';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'home',
      loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      data: {title: 'Home', breadcrumb: 'Home'}
    },
    {
      path: 'messages',
      loadChildren: () => import('./modules/message/message.module').then(m => m.MessageModule),
      data: {title: 'Messages', breadcrumb: 'Messages'}
    },
    {
      path: 'logbook',
      loadChildren: () => import('./modules/logbook/logbook.module').then(m => m.LogbookModule),
      data: {title: 'Logbook', breadcrumb: 'Logbook'}
    },
    {
      path: 'six_steps',
      loadChildren: () => import('./modules/six-steps/six-steps.module').then(m => m.SixStepsModule),
      data: {title: 'Six Steps', breadcrumb: 'Six steps'}
    },
    {
      path: 'add_learner_report',
      loadChildren: () => import('./modules/logbook/add-learner-report/add-learner-report.module').then(m => m.AddLearnerReportModule),
    },
    {
      path: 'add_admin_report',
      loadChildren: () => import('./modules/logbook/add-admin-report/add-admin-report.module').then(m => m.AddAdminReportModule),
    },
    {
      path: 'settings', loadChildren: '@cbdc/eswp-client-ui#AccountModule'
    },
    {
      path: '**', redirectTo: 'home'
    }
  ]),
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
