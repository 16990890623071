import {DomSanitizer, Title} from '@angular/platform-browser';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MediaObserver} from '@angular/flex-layout';
import {
  AuthenticationService,
  CredentialsService,
  I18nService,
  NavigationService,
  ValueObjectService,
  Configuration
} from '@cbdc/eswp-client-core';
import {Subscription} from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {UrlSegment} from '@angular/router';


@Component({
  selector: 'lib-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  private menuItemsSub: Subscription;
  apps: any[];

  get primaryApps(): any[] {
    if (this.apps === undefined) {
      return null;
    }
    return this.apps.filter(f => f.order < 100);
  }

  get secondaryApps(): any[] {
    if (this.apps === undefined) {
      return null;
    }
    return this.apps.filter(f => f.order >= 100);
  }

  public taskbarMenuItems: any[];
  private taskbarMenuItemsSub: Subscription;
  userData: any;

  routerEventSub: Subscription;
  currentRoute: ActivatedRoute;
  hideTaskBar = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private media: MediaObserver,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private translate: TranslateService,
    private navService: NavigationService,
    private dataCacheService: ValueObjectService,
    private authService: AuthenticationService,
    private config: Configuration,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,

  ) {
    iconRegistry.addSvgIcon(
      'hr_tool_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/hr/icon.svg'));
    iconRegistry.addSvgIcon(
      'manager_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/manager/icon.svg'));
    iconRegistry.addSvgIcon(
      'performance_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/performance/icon.svg'));
    iconRegistry.addSvgIcon(
      'account_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/account/icon.svg'));
    iconRegistry.addSvgIcon(
      'tp_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/tp/icon.svg'));
    iconRegistry.addSvgIcon(
      'learner_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/learner/icon.svg'));
    iconRegistry.addSvgIcon(
      'coach_icon',
      sanitizer.bypassSecurityTrustResourceUrl('https://skillsinactionportal.blob.core.windows.net/assets/Images/apps/coach/icon.svg'));

    this.routerEventSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        this.navService.setTaskbarMenu([]);
        let r = this.route.firstChild;
        while (r) {
          this.currentRoute = r;
          r = r.firstChild;
        }
        if (this.currentRoute.snapshot.data.hideTaskBar) {
          this.hideTaskBar = true;
        } else {
          this.hideTaskBar = false;
        }
      });
  }

  ngOnInit() {
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem; // .filter(item => item.type !== 'icon' && item.type !== 'separator');
    });

    // if (window.location.hash) {
    //   this.securityService.AuthorizedCallback();
    // }

    this.authService.isAuthenticated$.subscribe(() => {
      switch (this.authService.identityClaims.language) {
        case '1':
          this.i18nService.language = 'en';
          break;
        case '2':
          this.i18nService.language = 'fr';
          break;
      }
      //loading it after language change
      this.dataCacheService.getApps().subscribe(d => {
        this.apps = d;
        this.apps.forEach((a) => this.translate.get('app.' + a.code).subscribe(t => a.name = t));
      });

      this.taskbarMenuItemsSub = this.navService.taskBarItems$.subscribe((menuItems: any[]) => {
        this.taskbarMenuItems = menuItems.filter((menuItem) => {
          if (menuItem.permittedRoles) {
            return this.authService.isInRole(menuItem.permittedRoles);
          } else {
            return true;
          }
        });
      });

      this.userData = this.authService.identityClaims;
    });
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.authService.logout();
    // this.authenticationService.logout().subscribe(() => window.location.href = '/account/login');
  }
  navigateToProfile(){
      
      if(this.i18nService.language === 'en'){
        window.location.href = this.config.identityUrl + "/en/Manage/Info";

      }else{
        window.location.href = this.config.identityUrl + "/fr/Manage/Info";

      }

  }


  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get CurrentLanguage(): string {
    return this.i18nService.language;
  }

  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  ngOnDestroy() {
    // if(this.sidebarPS) {
    //   this.sidebarPS.destroy();
    // }
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }

    if (this.taskbarMenuItemsSub) {
      this.taskbarMenuItemsSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }

  }

  back() {
    this.currentRoute.url.subscribe((urls: UrlSegment[]) => {
      if (urls.length > 0) {
        //example: organisations/orgId/learnerId 
        if(this.currentRoute.snapshot.params.learnerId && this.currentRoute.snapshot.params.id){
          this.router.navigate(['./' + this.currentRoute.parent.snapshot.url[0].path + '/' + urls[0].path]);
        }else{
          this.router.navigate(['./'], {relativeTo: this.currentRoute.parent});
        }
      } else {
        this.router.navigate(['./'], {relativeTo: this.currentRoute.parent.parent});
      }
    });
  }
}
