import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from './authentication.service';
import { filter, map, switchMap, tap} from 'rxjs/operators';
import { Configuration } from '../types';


@Injectable()
  export class RoleAuthenticationGuard implements CanActivate{
    constructor(private authService: AuthenticationService, private router: Router, private config: Configuration){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.isIdentified$.pipe(
            filter(isDone =>isDone),
            map(x => {
                return this.checkUserRole(route);
            })
        );
    }

    checkUserRole(route: ActivatedRouteSnapshot){
        var auth = this.authService.isInRole(route.data.roles)
        if(!auth){
            this.authService.forceLogOut(); 
        }
        return auth;


    }

  }