import { Component, OnInit } from '@angular/core';
import {SecurityService} from '@cbdc/eswp-client-core';

@Component({
  selector: 'app-authcallback',
  template: `
    <p>
      Loading...
    </p>
  `,
  styles: []
})
export class AuthCallbackComponent implements OnInit {

  constructor(private securityService: SecurityService) { }

  ngOnInit() {
    this.securityService.AuthorizedCallback();
  }

}
